const TheStrangeHalfAbsenceOfWanderingAtNightContent = {
    german: {
        date: "4. Dezember 2020",
        arrow: "BLOG << ",
        headline: "The Strange Half-Absence Of Wandering At Night",
        captionFirst: "Fotos Luisa Riekes",
        firstFirst: "Zum ",
        firstLink: "Download",
        first: " des Audiowalks und der zusätzlichen Materialien",
        second: "Nachts spazieren gehen, sich einfach durch die Straßen treiben lassen? Für viele, vor allem für weiblich gelesene, Personen ist das mit Angst verbunden. Von klein auf kriegen insbesondere Mädchen erzählt, dass es für sie nicht mehr sicher draußen ist sobald es dunkel wird. Wer doch unterwegs sein muss, geht häufig Umwege, geht schneller oder langsamer, achtet auf jedes Geräusch – jede Bewegung ist bewusst; sich aber Zeit nehmen zum Treiben lassen und Verlaufen? Der Audiowalk „The Strange Half-Absence of Wandering At Night“ von Johanna Steindorf begleitet historische und fiktive Frauenfiguren mit Einkehr der Dämmerung bis in die Nacht und versucht den Spieß umzudrehen: Was ist, wenn die Nacht auch Schutz bieten kann? Wenn sie uns unsichtbar macht, Eindeutigkeiten verwischt, kann sie auch Freiheit bieten? Oder müssen sich als weiblich gelesene Körper die Freiheit durch die Nacht zu flanieren immer wieder neu aneignen?",
        captionSecond: "Audiowalk und Gespräch im hybriden Format",
        third: "Am 28. und 29. November 2020 sind wir den Walk gemeinsam – jede:r der jeweils 25 Teilnehmer:innen für sich ab der eigenen Haustür – gelaufen und haben danach mit Johanna Steindorf per Videokonferenz gesprochen. Zentral waren der Austausch über eigene Erfahrungen und Strategien des nächtlichen Umherschweifens sowie Überlegungen, was radikale Flânesuerie in diesem Sinne sein könnte. Welche Perspektiven fehlen vielleicht noch? Was ist mit dem Erleben rassifizierter, queerer, be_hinderterter Körper? Was liegt noch im Dunkeln, wenn wir über das nächtliche Bewegen nachdenken? Und wo können wir uns zusammenschließen und gemeinsam flanieren? Wir waren uns jedenfalls einig, dass radiale Flâneuserie eine kollektive, fürsorgliche Angelegenheit ist.",
        fourth: "Wer die Nacht wie wahrnimmt und empfindet, hängt von unserer Sozialisation, unseren Erfahrungen, Erinnerungen und Eindrücken ab; davon wie wir gesellschaftlich verortet sind und wo wir uns wie bewegen (können). Die gemeinsamen Diskussionen und Gespräche in der Veranstaltung haben vielleicht mehr Fragen aufgeworfen als Antworten geliefert. Aber die Suche nach nocturnal feminism hat ja auch gerade erst begonnen…",
        fifthSecond: "Wer keinen Platz mehr für einen der Termine ergattern konnte, kann sich den Audiowalk sowie den Mitschnitt des Gesprächs mit Johanna Steindorf inkl. englischer/deutscher Transkripte vom 28. November hier herunterladen. An diesem Tag war ebenfalls ",
        fifthLinkSecond: "Neha Singh",
        fifthThird: " zu Gast, die gemeinsam mit anderen Frauen nachts durch die Mumbaier Straßen zieht und das gemeinsame Rumhängen [loitering] aktivistisch praktiziert. Neha wird auch im Mai 2021 zum Festival mit einem eigenen Beitrag kommen.",
        thirdHeadline: "Mehr zur Künstlerin:",
        sixth: "Johanna Steindorf ist Medienkünstlerin, hat in Rio de Janeiro und Köln studiert und ihre Doktorarbeit zum “Audiowalk als künstlerische Praxis und Methode” an der Bauhaus-Universität in Weimar geschrieben. Neben ihrer künstlerischen Arbeit lehrt sie auch als Dozentin u.a. an der Uni Köln und der Uni Siegen. Geboren in Ecuador, aufgewachsen in verschiedenen Ländern, lebt sie seit 2006 in Deutschland. Ihre eigenen Erfahrungen fließen in ihre Arbeit ein, die sich mit Geschlecht, Migration und dem öffentlichen Raum – vor allem aber dem Umherschweifen und Bewegen darin beschäftigen und dabei auch die Themen Nacht und Dunkelheit berühren, wie die auto-biografisch geprägte Audio-Installation Lusco-Fusco. Wir können euch nur ans Herz legen, euch auch andere Arbeiten von Johanna anzuschauen. Mehr zu Johanna und ihren Arbeiten findet ihr auf ",
        sixthLink: "www.johannasteindorf.de.",
        secondHeadline: "Downloads",
        thirdHeadlineFirst: "Audiowalk „The Strange Half-Absence Of Wandering At Night“",
        thirdHeadlineSecond: "Audio „Künstlerinnen-Gespräch / Q&A with the Artist“",
        transcriptSecond: "Transkript des Mitschnitts (DE) (PDF)",
        seventhLink: "DIGITALE PINNWAND DES AUDIOWALKS",
        sponsor: "Gefördert durch",
        linkFirst: "/documents/blog/Script_Audiowalk_DE.pdf",
        linkSecond: "/documents/blog/QA_transkript_Strange_Half-Absence_DE.pdf",
        altFirst: "Zwei Personen laufen durch einen Park in der Dämmerung.",
        altSecond: "Personen laufen in der Dämmerung unter Bäumen.",
        altThird: "Screenshot von der Videokonferenz vom Künstlerinnen-Gespräch mit Johanna Steindorf.",
        altFourth: "Mit Mentimeter erstellte Wortwolke mit Eindrücken der Teilnehmer:innen des Audiowalks. ",
        soundcloudFirst: "Zum Reinhören und Streamen einfach auf das orange Play-Zeichen klicken; zum Download oben rechts in der Ecke auf den weißen Pfeil klicken.",
        soundcloudSecond: "Zum Reinhören und Streamen einfach auf das orange Play-Zeichen klicken.",

    },
    english: {
        date: "December 4, 2020",
        arrow: "BLOG << ",
        headline: "The Strange Half-Absence Of Wandering At Night",
        captionFirst: "Photos Luisa Rieke",
        firstLink: "Download",
        first: " the Audiowalk and material",
        second: "Taking a walk at night, just letting yourself drift through the streets? For many people, especially people read as woman, this is associated with fear. From an early age, girls in particular are told that it is no longer safe for them to go outside once it gets dark. If we as women do have to be on the road, we take detours, walk faster or slower, pay attention to every noise – but in any case consciously; there is no time to drift around and get lost. The audio walk „The Strange Half-Absence of Wandering At Night“ by Johanna Steindorf accompanies historical and fictional female characters as they walk through the twilight until late at night, trying to turn the tables: What if the night can also offer protection? If it makes us invisible, blurs ambiguities, can it also offer freedom?",
        captionSecond: "audiowalk and talk took place in a hybrid format",
        third: "On 28 and 29 November 2020 we walked the walk together – each of us separately from their front door – and afterwards we talked to Johanna Steindorf via video conference. The exchange about our own experiences and strategies of wandering around at night as well as thoughts about what radical flâneuserie in this sense could be were central. Which perspectives are perhaps still missing? What about racialised, queer, disabled bodies? What is still in the dark when we think about moving around at night? And where can we join forces and stroll together? In any case, we agreed that radial flâneuserie is a collective, caring matter.",
        fourth: "Who perceives and feels the night and how depends on our socialisation, our experiences, memories and impressions; on how we are socially located and where we (can) move and how. The joint discussions and conversations during the event have raised more questions (than they have provided answers) which maybe still bring us closer to finding out what nocturnal feminism is all about. But the search has only just begun…",
        fifthFirst: "Those who were unable to get a spot (and everyone else, of course) can download the audio walk (in English language) and the recording of the conversation with Johanna Steindorf including English/German transcripts from 28th November ",
        fifthLink: "here",
        fifthSecond: ". Artist and activist ",
        fifthLinkSecond: "Neha Singh",
        fifthThird: " was also our guest on this day. Together with other women, she roams the streets of Mumbai at night and practices loitering as political praxis. Neha will contributing to the festival in May 2021 as a speaker as well.",
        thirdHeadline: "More about the Artist:",
        sixth: "Johanna Steindorf is a media artist who studied in Rio de Janeiro and Cologne and wrote her doctoral thesis on „Audiowalk as artistic practice and method“ at the Bauhaus University in Weimar. Besides her artistic work she also works as a lecturer at the University of Cologne and the University of Siegen, among others. Born in Ecuador, grown up in different countries, she has been living in Germany since 2006. Her own experiences influence her work, which deals with gender, migration and the public space – but above all with wandering and moving around in the latter, and in doing so it touches on the themes of night and darkness, such as the auto-biographical audio installation Lusco-Fusco. We can only recommend that you take a look at other works by Johanna. You can find more about Johanna and her work at ",
        sixthLink: "www.johannasteindorf.de.",
        secondHeadline: "Downloads",
        thirdHeadlineFirst: "Audiowalk „The Strange Half-Absence Of Wandering At Night“",
        thirdHeadlineSecond: "Audio „Künstlerinnen-Gespräch / Q&A with the Artist“",
        transcriptSecond: "Transcript of the Q&A (EN) (PDF)",
        seventhLink: "DIGITAL PINBOARD ABOUT THE AUDIOWALK",
        sponsor: "Sponsored by",
        linkFirst: "/document/blog/Script_Audiowalk_EN.pdf",
        linkSecond: "/documents/blog/QA_transkript_Strange_Half-Absence_EN.pdf",
        altFirst: "Two people walk through a park in the twilight.",
        altSecond: "People walking unter trees in the twilight. ",
        altThird: "Screenshot from the video conference of the artist talk with Johanna Steindorf.",
        altFourth: "Word cloud created with Mentimeter with impressions from the participants of the audio walk. ",
        soundcloudFirst: "To listen and stream, simply click on the orange play sign; to download, click on the white arrow in the top right corner.",
        soundcloudSecond: "To listen and stream, simply click on the orange play sign.",
        
    }

}
export {TheStrangeHalfAbsenceOfWanderingAtNightContent}