import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { TheStrangeHalfAbsenceOfWanderingAtNightContent } from "../../content/blog/the-strange-half-absence-of-wandering-at-night"
import { SettingsContext } from "../../contexts/settings"

const TheStrangeHalfAbsenceOfWanderingAtNightPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = TheStrangeHalfAbsenceOfWanderingAtNightContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
                <div className="end-of-site">
                    <h1>
                        <Link to="/blog#the-strange-half-absence-of-wandering-at-night" class="back-arrow">{content.arrow}</Link>
                        {content.headline}
                    </h1>
                    <p>{content.date}</p>
                    <div className="image-banner">
                        <img className="image-half" src="/images/blog/rsz_10_btour_luisariekes1.jpg" alt={content.altFirst} />
                        <img className="image-half" src="/images/blog/10_BTour_LuisaRiekes2.jpg" alt={content.altSecond} />
                    </div>
                    <p className="small caption">{content.captionFirst}</p>
                    <p>
                        {content.firstFirst}
                        <Link className="link" to="#downloads" >{content.firstLink}</Link>
                        {content.first}
                    </p>
                    <p>
                        {content.second}

                    </p>
                    <img className="centered image-big" src="/images/blog/Screenshot_2020-11-28.png" alt={content.altThird} />
                    <p className="small caption">{content.captionSecond}</p>
                    <p>
                        {content.third}
                    </p>
                    <p>
                        {content.fourth}
                    </p>
                    <p>
                        {content.fifthFirst}
                        <a className="link" href="https://www.facebook.com/events/892243821305440/" target="_blank" rel="noopener noreferrer">{content.fifthLink}</a>
                        {content.fifthSecond}
                        <a className="link" href="https://www.instagram.com/khalisi_singh/" target="_blank" rel="noopener noreferrer">{content.fifthLinkSecond}</a>
                        {content.fifthThird}
                    </p>
                    <h3>{content.thirdHeadline}</h3>
                    <p>
                        {content.sixth}
                        <a className="link" href="http://www.johannasteindorf.de/" target="_blank" rel="noopener noreferrer">{content.sixthLink}</a>
                    </p>
                    <div className="image-banner">
                        <img className="image-half" src="/images/blog/menti2.jpg" alt={content.altFourth} />
                        <img className="image-half" src="/images/blog/menti1.jpg" alt={content.altFourth} />
                    </div>
                    <h3 id="downloads">
                        {content.secondHeadline}
                    </h3>
                    <h4>{content.thirdHeadlineFirst}</h4>
                    <div className="soundcloud centered">
                        <iframe title="The Strange Half-Absence Of Wandering At Night by jojo.st" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?visual=true&amp;url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F211428356&amp;show_artwork=true&amp;maxwidth=640&amp;maxheight=960&amp;dnt=1&amp;secret_token=s-xeZqO"></iframe>
                        <p className="caption small">{content.soundcloudFirst}</p>
                    </div>
                    <a className="link" href={content.linkFirst} target="_blank" rel="noopener noreferrer">Transkript des Audiowalks inklusive Quellen (DE) (PDF)</a><br/>
                    <a className="link" href={content.linkFirst} target="_blank" rel="noopener noreferrer">Transcript of the audiowalk plus sources (DE) (PDF)</a>
                    <h4>{content.thirdHeadlineSecond}</h4>
                    <div className="soundcloud centered ">
                        <iframe title="Q and A Steindorf Audiowalk The Strange Half-Absence of Wandering at Night" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/940732708&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                        <p className="caption small">{content.soundcloudSecond}</p>
                    </div>
                    <a className="link" href={content.linkSecond} target="_blank" rel="noopener noreferrer">{content.transcriptSecond}</a>
                </div>
            </section>
        </Template >
    )
}

export default TheStrangeHalfAbsenceOfWanderingAtNightPage